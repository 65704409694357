<template>
  <div>
    <!-- Outer body of the toast -->
    <div class="toast_outer shadow rounded">
      <b-container class="h-100 position-relative">
        <!-- Row shown when the action is submitted and am waiting for a response -->
        <transition name="fade" @leave="leave">
          <b-row v-if="loading" no-gutters class="h-100">
            <b-col cols="12" class="d-flex align-items-center justify-content-center position-relative">
              <i class="fas fa-spinner fa-2x text-primary fa-spin"></i>
            </b-col>
          </b-row>
        </transition>
        <!-- Row shown when action succeeds -->
        <transition name="fade" @leave="leave">
          <b-row v-if="!error && !loading" no-gutters class="h-100">
            <b-col cols="2" class="d-flex align-items-center justify-content-center">
              <i class="fas fa-dumbbell text-muted"></i>
            </b-col>
            <b-col cols="10" class="d-flex flex-column justify-content-center">
              <h6 class="my-0 ml-3 d-block text-success font-weight-bold">
                {{ message }}
              </h6>
              <small v-if="sideMessage != null" class="text-muted ml-3 d-block text-bold m-0">{{ sideMessage }}</small>
            </b-col>
          </b-row>
        </transition>
        <!-- Row shown when action fails -->
        <transition name="fade" @leave="leave">
          <b-row v-if="error && !loading" no-gutters class="h-100">
            <b-col cols="2" class="d-flex align-items-center justify-content-center">
              <i class="fas fa-times-circle fa-lg text-muted"></i>
            </b-col>
            <b-col cols="10" class="d-flex flex-column justify-content-center">
              <h6 class="my-1 ml-3 d-block text-danger font-weight-bold">{{ message }}</h6>
              <small v-if="sideMessage != null" class="text-muted ml-3 m-0 d-block text-bold">{{ sideMessage }}</small>
            </b-col>
          </b-row>
        </transition>
        <!-- Close icon for closing the toast -->
        <i v-if="!loading" class="fas fa-times text-muted pointer" @click="close_toast"></i>
      </b-container>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

// Import mutation to close and reset the toast
import { TOASTS_CLOSE, TOASTS_DECISION } from '@/store/modules/toasts/mutation-types.js';

export default {
  components: {},
  data() {
    return {};
  },
  computed: {
    ...mapState({
      error: (state) => state.toasts.error,
      message: (state) => state.toasts.message,
      sideMessage: (state) => state.toasts.sideMessage,
      loading: (state) => state.toasts.loading,
    }),
  },
  methods: {
    // Method called to close the toast
    close_toast() {
      this.$store.commit(TOASTS_CLOSE);
      this.$store.commit(TOASTS_DECISION, null);
    },
    // Load prev data
    load_data_decision(resp) {
      this.$store.commit(TOASTS_DECISION, resp);
      this.$store.commit(TOASTS_CLOSE);
    },
    /**
     * Then move to the login screen
     */
    leave(el, done) {
      el.style.position = 'absolute';
      done();
    },
  },
};
</script>

<style scoped>
/* Outer body styling of the toast */
.toast_outer {
  width: 100%;
  height: 95px;
  background: white;
}

@media only screen and (min-width: 768px) {
  .toast_outer {
    width: 350px;
    height: 95px;
    background: white;
  }
}

/* Close icon styling */
.fa-times {
  position: absolute;
  top: 10px;
  right: 10px;
}
</style>
